@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Chonburi&family=Fjalla+One&family=Limelight&family=Lobster&family=Lora:wght@500&family=Merriweather&family=Murecho:wght@400;600&family=PT+Mono&family=Raleway:wght@400;700&family=Sora:wght@400;700&display=swap");

:root {
  background-color: black;
  font-size: 2.5vh;
  line-height: 1.4rem;
  text-align: center;
  overflow-y: scroll;
  overflow-x: hidden;
  box-sizing: border-box;

  --border: 3px solid rgb(238, 238, 238);
  --border-thin: 2px solid rgb(102, 102, 102);
  --border-dark: 3px solid rgb(34, 33, 33);
  --border-radius: 6px;
  --box-shadow: -2px -2px 10px rgba(37, 37, 37, 0.5),
    2px 2px 10px rgba(27, 27, 27, 0.5);
  --color-title-text: rgb(255, 255, 255);
  --color-white-bkg: rgb(255, 255, 255);
  --color-black-bkg: rgb(0, 0, 0);
  --color-light-bkg: rgb(247, 247, 247);
  --color-transblack-bkg: rgba(8, 8, 8, 0.753);
  --color-transgray-bkg: rgba(7, 0, 4, 0.89);
  --color-light-text: rgb(240, 240, 240);
  --color-dark-text: rgb(54, 54, 54);
  --color-link-text: rgb(253, 187, 74);
  --font-stylesfont-family: "Bebas Neue", sans-serif;
  font-family: "Chonburi", cursive;
  font-family: "Limelight", cursive;
  font-family: "Lobster", cursive;
  font-family: "Lora", serif;
  font-family: "Merriweather", serif;
  font-family: "Murecho", sans-serif;
  font-family: "PT Mono", monospace;
  font-family: "Raleway", sans-serif;
  font-family: "Sora", sans-serif;
  --font-nav: 500 0.6rem "Murecho", sans-serif;
  --font-nav-title: 500 0.8rem "Murecho", sans-serif;
  --font-code-title: 500 2rem "PT Mono", monospace;
  --font-creative-title: 400 2rem "Lobster", cursive;
  --font-hero-title: 400 4.5rem "Fjalla One", sans-serif;
  --font-content-title: 700 2.6rem "Murecho", sans-serif;
  --font-card-title: 400 2.6rem "Bebas Neue", sans-serif;
  --font-card-text: 500 0.8rem "Murecho", sans-serif;
  --box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.6);

  --animation-slide-speed: 0.5s;
  --padding-standard: 1.5rem;
  --padding-little: 0.5rem;
}

h1 {
  display: inline;
}
ul {
  padding-left: 20px; /* Adjust the value to your desired indent size */
}

a {
  color:var(--color-link-text);
  text-decoration: underline;
}
.container {
  position: relative;
  margin: auto;
  margin-top: 1vh;
  width: 100%;
  height: fit-content;
  display: grid;
  align-items: center;
  justify-items: center;
  grid-template-columns: 100%;
  grid-template-areas: "page-container";
}
.fade-background {
  position: absolute;
  z-index: -2;
  opacity: 1;
  top: 12vh;
  left: 0;
  width: 100%;
  height: auto;
  transition: opacity 0.3s;
}
.fade-background-lower {
  position: absolute;
  z-index: -2;
  opacity: 1;
  top: 30vh;
  left: 0;
  width: 100%;
  height: auto;
  transition: opacity 0.3s;
}
.backgroundImage,
.backgroundImage-two {
  position: absolute;
  top: 20%;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: top;
  background-position-y: 25vh;
  background-repeat: no-repeat;
  background-blend-mode: darken;
  transition: opacity 0.3s;
}

.backgroundImage {
  background-image: url("./images/black&white_bk2.png");
  z-index: -2;
  opacity: 1;
}

.backgroundImage-two {
  background-image: url("./images/black&white_bk1.png");
  z-index: -1;
  opacity: 1;
  background-position-y: 20vh;
}
/* TITLE BAR-------------------------*/
.title-bar {
  margin-bottom: 20vh;
  width: auto;
  padding: var(--padding-little);
  border: var(--border-dark);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  background-color: var(--color-transblack-bkg);
  font: var(--font-code-title);
  grid-area: title-bar;
}
.title-bar-text {
  margin: auto;
  background-color: var(--color-black-bkg);
  border: var(--border-thin);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  max-width: fit-content;
  padding-inline: var(--padding-standard);
  padding-block: var(--padding-little);
  font: var(--font-content-title);
  color: var(--color-light-text);
}

.typing-animation {
  animation: typing var(--shine-duration) steps(40) 1s forwards;
  opacity: 0;
  white-space: nowrap;
  overflow: hidden;
  border-right: 0.15em solid var(--color-title-text);
  width: 0;
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

/*-HOME--------------->*/
.home-container {
  margin: auto;
  margin-top: 12vh;
  width: 100%;
  height: fit-content;
  display: grid;
  justify-items: center;
  grid-template-columns: 100%;
  grid-template-rows:
    60vh auto auto auto auto
    10vh;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas:
    "hero-container"
    "current-project-container"
    "about-container"
    "prompt-container"
    "project-container"
    "footer-container";
  grid-area: "page-container";
}
/* NAV-------------------*/
.nav-container {
  position: fixed;
  top: 0;
  left: 0;
  font: var(--font-nav);
  color: white;
  border-radius: 6px 6px 0 0;
  transform: translateY(-200%);
  display: grid;
  background-color: rgba(68, 68, 68, 0.2); /* Slight gray tone */
  z-index: 1000;
  width: 100%;
  height: 6vh;
  grid-template-rows: 6vh;
  grid-template-columns: 40vw 40vw;
  grid-template-areas: "title-area" "nav-area";
  backdrop-filter: blur(10px); /* Apply the blur effect */
  animation-name: slideIn;
  animation-duration: var(--animation-slide-speed);
  animation-delay: 0.5s;
  animation-fill-mode: forwards;
}
@keyframes slideIn {
  0% {
    transform: translateY(-200%);
  }
  100% {
    transform: translateY(0);
  }
}
.nav-area {
  margin: auto;
  align-items: end;
  text-align: end;
  width: 50vw;
  max-height: 8vh;
  grid-area: "nav-area";
  font: var(--font-nav);
}

.title-area {
  margin: auto;
  width: 60vw;
  max-height: 8vh;
  text-align: start;
  margin-inline-start: 5vw;
  grid-area: "title-area";
  font: var(--font-nav-title);
}

/*HERO------------------->*/
.hero-container {
  color: white;
  width: 60vw;
  display: grid;
  text-align: start;
  margin: auto;
  height: 60vh;
  grid-template-columns: auto;
  grid-template-rows: 20vh 20vh 20vh;
  grid-template-areas:
    "hero-intro-area"
    "hero-title-area"
    "hero-text-area";
  grid-area: hero-container;
}
.hero-intro-area {
  border: var(--border);
  height: fit-content;
  border-radius: 10px;
  background-color: var(--color-transgray-bkg);
  padding: var(--padding-standard);

  grid-area: hero-intro-area;
}
.hero-title-area {
  height: fit-content;
  transform: scaleY(1.2);
  font: var(--font-hero-title);
  text-shadow: var(--box-shadow);

  color: var(--color-light-text);
  grid-area: hero-title-area;
  position: relative;
  overflow: hidden;
}

.long-dash {
  margin: auto; /* Adjust the width as desired */
  display: inline-block;
  transform: scaleX(1.2);
}
.hero-text-area {
  height: fit-content;
  border: var(--border);
  border-radius: 10px;
  background-color: var(--color-transgray-bkg);
  padding: var(--padding-standard);

  grid-area: hero-text-area;
}
/*-CURRENT PROJECT---------------->*/
.current-project-container {
  position: relative;
  margin-top: 10vh;
  width: 100vw;
  display: grid;
  grid-template-columns: 100vw;
  grid-template-rows: auto auto;
  grid-template-areas:
    "title-bar"
    "current-card-container";
  grid-area: current-project-container;
}
.current-card-container {
  margin: auto;
  margin-top: -10vh;
  position: relative;
  width: 90vw;
  display: grid;
  gap: 2vw;
  grid-template-columns: 1fr 1fr;
  grid-area: current-card-container;
}
/* ABOUT----------------------->*/
.about-container {
  position: relative;
  color: var(--color-light-text);
  margin: auto;
  width: 100%;
  height: fit-content;
  display: grid;
  justify-items: center;
  grid-template-columns: 100vw;
  grid-template-rows: 30vh auto auto auto 10vh;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas:
    "about-title"
    "profile-container"
    "sofware-container"
    "skill-card-container"
    "footer-container";
  grid-area: "about-container";
}
.about-title {
  margin-top: 10vh;
  font: var(--font-hero-title);
  grid-area: about-title;
}
.profile-container {
  display: grid;
  margin: auto;
  margin-top: 10vh;
  width: 54vw;
  justify-content: center;
  border: var(--border-dark);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  background-color: var(--color-black-bkg);
  color: var(--color-light-text);
  justify-items: center;
  grid-template-columns: 30vw 20vw;
  grid-template-rows: 30vh auto;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas:
    "profile-title profile-pic "
    "profile-text profile-text";

  grid-area: "profile-container";
}
.profile-pic {
  margin: auto;
  padding: var(--padding-little);
  margin-top: 5vh;
  height: 25vh;
  grid-area: profile-pic;
}
.profile-title {
  text-align: center;
  margin: auto;
  font: var(--font-content-title);
  transform: scaleY(1.2);
  font-size: 1.5rem;
  grid-area: profile-title;
}
.profile-text h4 {
  text-align: center;
}
.profile-text {
  padding: var(--padding-standard);
  text-indent: 1.2rem;
  text-align: justify;
  text-justify: inter-word;
  text-align: justify;
  text-justify: inter-word;
  word-spacing: 0.1rem; /* Adjust this value to your preference */
  line-height: 1.5rem;
  height: auto;

  margin: auto;
  padding: var(--padding-standard);
  color: var(--color-light-text);
  grid-area: profile-text;
}
.skill-card-container {
  display: grid;
  margin-top: 15vh;
  width: fit-content;
  padding: var(--padding-standard);
  height: auto;
  border: var(--border-dark);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  background-color: var(--color-black-bkg);
  color: var(--color-light-text);
  justify-content: center;
  grid-template-columns: 45vw;
  grid-template-rows: auto;
  gap: 0px 0px;
  grid-template-areas: "skill-card";
}

.skill-card-title {
  font: var(--font-card-title);

  grid-area: skill-card-title;
}
.skill-card-markdown {
  text-align: start;
  grid-area: skill-card-markdown;
}

.software-container {
  margin-top: 15vh;
  border: var(--border);
  border-radius: 10px;
  background-color: var(--color-transgray-bkg);
  padding: var(--padding-little) var(--padding-standard);
  display: grid;
  width: fit-content;
  gap: 5vw;
  height: auto;
  text-align: start;
  grid-template-columns: 20vw 20vw;
  grid-template-areas: "software-code software-creative";
  grid-area: "software-container";
}
.software-code {
  grid-area: software-code;
}
.software-creative {
  grid-area: software-creative;
}
/* CONTENT--------------------->*/
.content-container {
  margin: auto;
  position: relative;
  width: 100vw;
  margin-top: 10vh;
  display: grid;
  gap: 2vw;
  grid-template-columns: 1fr 1fr;
  grid-area: content-container;
}

.current-card {
  display: grid;
  margin-top: 10vh;
  justify-self: end;
  border: var(--border-dark);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  background-color: var(--color-black-bkg);
  color: var(--color-light-text);
  overflow: hidden;
  height: fit-content;
  width: fit-content;
  grid-template-columns: 30vw;
  grid-template-rows: 12vh 8vh 35vh 20vh;
  grid-template-areas:
    "home-card-title "
    "home-card-link"
    "home-card-image"
    "home-card-description";
  opacity: 0;
}
.current-card:nth-child(even) {
  justify-self: start;
}

.current-card-link {
  margin-top: 2vh;
  margin-inline: 2vw;
  grid-area: home-card-link;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.current-card-image {
  border-radius: var(--border-radius);
  margin-top: 2vh;
  margin-inline: 2vw;
  height: 30vh;
  width: auto;
  grid-area: home-card-image;
  transition: filter var(--animation-slide-speed) ease-in-out;
}

.current-card-title {
  padding: var(--padding-standard);
  font: var(--font-card-title);
  font-size: 3em;
  transform: scaleY(1.5);
  grid-area: home-card-title;
}

.current-card-description {
  padding: var(--padding-little) var(--padding-standard);
  font-size: 0.8rem;
  grid-area: home-card-description;
}
.home-card {
  display: grid;
  margin: auto;
  margin-top: 15vh;
  border: var(--border-dark);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  background-color: var(--color-black-bkg);
  color: var(--color-light-text);
  overflow: hidden;
  height: fit-content;
  width: fit-content;
  grid-template-columns: 30vw 30vw;
  grid-template-rows: 20vh 20vh 10vh;
  grid-template-areas:
    "home-card-image home-card-title"
    "home-card-image home-card-description"
    "home-card-link home-card-description";
  opacity: 0;
  transform: translateX(-100%);
}

.home-card:nth-child(even) {
  grid-template-areas:
    "home-card-title home-card-image"
    "home-card-description home-card-image"
    "home-card-description home-card-link";
}

@keyframes slide-in-right {
  0% {
    transform: skewX(10deg) translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slide-in-left {
  0% {
    transform: skewX(-10deg) translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.slide-in-right {
  animation: slide-in-right var(--animation-slide-speed) forwards ease-in-out;
}

.slide-in-left {
  animation: slide-in-left var(--animation-slide-speed) forwards ease-in-out;
}

.home-card-link {
  padding: var(--padding-standard);
  grid-area: home-card-link;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.home-card-image {
  border-radius: var(--border-radius);
  margin-top: 2vh;
  margin-inline: 2vw;
  height: auto;
  width: 20vw;
  grid-area: home-card-image;
  transition: filter var(--animation-slide-speed) ease-in-out;
}

.home-card-title {
  padding: var(--padding-standard);
  font: var(--font-card-title);
  font-size: 3em;
  transform: scaleY(1.5);
  grid-area: home-card-title;
}

.home-card-description {
  padding: var(--padding-little) var(--padding-standard);
  grid-area: home-card-description;
}
/*--------PROMPT--------*/
.prompt-container {
  position: relative;
  overflow: visible;
  display: grid;
  margin-top: 10vh;
  width: 100vw;
  height: auto;
  color: var(--color-light-text);
  justify-content: center;
  grid-template-columns: 95vw;
  grid-template-rows: auto;
  gap: 5vh;
  grid-template-areas:
    "title-bar"
    "prompt-card";
  grid-area: prompt-container;
}

.prompt-card {
  margin: auto;
  margin-top: 10vh;
  padding: var(--padding-standard);
  border: var(--border-dark);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  background-color: var(--color-black-bkg);
  display: grid;
  width: 70vw;
  justify-items: center;
  height: fit-content;
  grid-template-columns: 30vw 40vw;
  grid-template-rows: 10vh auto auto;
  grid-template-areas:
    "prompt-card-image prompt-card-title"
    "prompt-card-image prompt-card-text"
    "prompt-card-image prompt-card-text";
  opacity: 0;

  transform: translateY(-20%);
  z-index: -1;
}
.prompt-card-title {
  margin: auto;
  padding: var(--padding-little) var(--padding-standard);
  text-align: center;
  font: var(--font-code-title);
  grid-area: prompt-card-title;
}
.prompt-card-image {
  margin: auto;
  justify-self: center;
  width: 22vw;
  height: auto;
  grid-area: prompt-card-image;
}
.prompt-card-text {
  padding: var(--padding-little) var(--padding-standard);
  justify-self: center;
  font: var(--font-card-text);
  text-align: justify;
  text-justify: inter-word;
  text-align: justify;
  text-justify: inter-word;
  word-spacing: 0.1rem; /* Adjust this value to your preference */
  line-height: 1.5rem;
  grid-area: prompt-card-text;
}
.slide-in-bottom {
  animation: slide-in-bottom var(--animation-slide-speed) forwards ease-in-out;
}
@keyframes slide-in-bottom {
  0% {
    transform: skewY(10deg) translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
/*------------PROJECTs---------*/
.project-container {
  position: relative;
  overflow: visible;
  display: grid;
  margin-top: 10vh;
  width: 100vw;
  height: auto;
  color: var(--color-light-text);
  justify-content: center;
  grid-template-columns: 100vw;
  grid-template-rows: auto;
  gap: 5vh;
  grid-template-areas:
    "title-bar"
    "current-card-container";
  grid-area: project-container;
}

/*---BUTTONS-------------------->*/
.nav-button {
  color: var(--color-light-text);
  text-decoration: none;
  padding-right: 2vw;
}
/*--------Resposive Design----------.*/

/* For screens smaller than 768px (e.g. smartphones) */
@media (max-width: 768px) {
  :root {
    /* Adjust font size for smaller screens */
    font-size: 1.6vh;
  }
  /* Modify grid emplate areas for smaller screens */
  h1 {
    font-size: 1.8rem;
  }

  .home-container {
    margin-top: 12vh;
    justify-items: center;
    grid-template-columns: 100%;
    grid-template-rows:
      50vh auto auto auto auto
      10vh;
  }
  .hero-container {
    width: 90vw;
    height: 50vh;
    grid-template-rows: 15vh 25vh 15vh;
  }
  .hero-intro-area {
    padding-inline: 5vw;
    padding-block: 1rem;
  }
  .hero-title-area {
    text-align: center;
    height: fit-content;
    transform: scaleY(1.2);
  }
  .hero-text-area {
    padding-inline: 5vw;
    padding-block: 1rem;
  }
  .title-bar {
    margin-bottom: 10vh;
    padding: 0.5rem;
  }
  .title-bar-text {
    padding-inline: 1rem;
    padding-block: 0.5rem;
  }
  .current-project-container {
    margin-top: 10vh;
    width: 100vw;
  }
  .current-card-container {
    margin-top: -10vh;
    width: 100vw;
    display: grid;
    gap: 2vw;
    grid-template-columns: auto;
    grid-template-rows: 1fr 1fr;
    grid-area: current-card-container;
  }
  .current-card {
    display: grid;
    margin-top: 5vh;
    justify-self: center;
    border: var(--border-dark);
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow);
    background-color: var(--color-black-bkg);
    color: var(--color-light-text);
    overflow: hidden;
    height: fit-content;
    width: fit-content;
    grid-template-columns: 70vw;
    grid-template-rows: 10vh auto auto auto;
    grid-template-areas:
      "home-card-title"
      "home-card-image"
      "home-card-link"
      "home-card-description";
    opacity: 0;
  }
  .current-card:nth-child(even) {
    justify-self: center;
  }

  .current-card-link {
    margin: auto;
    padding: 1rem;
    grid-area: home-card-link;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .current-card-image {
    border-radius: var(--border-radius);
    margin: auto;
    height: 30vh;
    width: auto;
    grid-area: home-card-image;
    transition: filter var(--animation-slide-speed) ease-in-out;
  }

  .current-card-title {
    margin: auto;
    font: var(--font-card-title);
    font-size: 3rem;
    transform: scaleY(1.5);
    grid-area: home-card-title;
  }

  .current-card-description {
    margin: auto;
    padding: 1.8rem;
    font-size: 1rem;
    grid-area: home-card-description;
    height: fit-content;
  }
  /* ABOUT-Responisive CellPhone---------------------->*/
  .about-container {
    width: 100%;
    justify-content: center;
    grid-template-rows: 20vh auto auto auto 10vh;
  }
  .about-title {
    margin-top: 5vh;
    font: var(--font-hero-title);
    grid-area: about-title;
  }
  .profile-container {
    margin-top: 10vh;
    width: 80vw;
    grid-template-columns: 80vw;
    grid-template-rows: 20vh auto;
    grid-template-areas:
      "profile-pic "
      "profile-title"
      "profile-text";
  }
  .profile-pic {
    margin: auto;
    padding: 2rem;
    height: 20vh;
  }
  .profile-title {
    padding: 2rem;
    transform: scaleY(1.2);
    font-size: 1.5rem;
  }
  .profile-text h4 {
    display: inline;
    text-align: center;
  }
  .profile-text {
    padding: 0.5rem 2rem;
    word-spacing: 0.1rem; /* Adjust this value to your preference */
  }
  .skill-card-container {
    margin-top: 5vh;
    width: fit-content;
    height: auto;
    justify-content: center;
    grid-template-columns: 60vw;
  }
  .skill-card- {
    display: grid;
    margin-top: 2vh;
    width: 80vw;
  }
  .software-container {
    margin-top: 5vh;
    border: var(--border);
    padding: 0.5rem 2rem;
    width: 70vw;
  }
  .software-code {
    grid-area: software-code;
  }
  .software-code {
    grid-area: software-code;
  }
  .prompt-container {
    margin-top: 5vh;
  }

  .prompt-card {
    margin-top: 5vh;
    width: 70vw;
    gap: 5px;
    justify-items: center;
    height: fit-content;
    grid-template-columns: 70vw;
    grid-template-rows: auto auto auto;
    grid-template-areas:
      "prompt-card-title"
      "prompt-card-image"
      "prompt-card-text";
  }
  .prompt-card-title {
    font-size: 3rem;
  }
  .prompt-card-image {
    justify-self: center;
    width: 22vw;
  }
  .prompt-card-text {
    word-spacing: 0.1rem; /* Adjust this value to your preference */
    line-height: 1.5rem;
    font-size: 1rem;
  }
  .home-card {
    margin-top: 5vh;
    grid-template-columns: 70vw;
    grid-template-rows: auto auto auto auto;
    grid-template-areas:
      "home-card-title"
      "home-card-image"
      "home-card-link"
      "home-card-description";
  }

  .home-card:nth-child(even) {
    grid-template-areas:
      "home-card-title"
      "home-card-image"
      "home-card-link"
      "home-card-description";
  }
  .home-card-link {
    padding: 1rem;
    grid-area: home-card-link;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .home-card-image {
    height: 30vh;
    width: auto;
    transition: filter var(--animation-slide-speed) ease-in-out;
  }

  .home-card-title {
    font-size: 3.5rem;
    transform: scaleY(1.5);
  }

  .home-card-description {
    margin-bottom: 5vh;
    padding: 1.5rem;
    font-size: 1rem;
  }
}
